import { SummaryAnalysisSample } from '@components/plots/PlotTypes';
import GeneSet from '@models/GeneSet';
import ScorePlotDatum from '@models/GeneSet';
import { ArrowData } from '@util/ObjectUtil';

export type ExperimentDataStatus = 'in_progress' | 'complete' | 'pending' | 'draft';
export type PipelineStatus = 'in_progress' | 'failed' | 'completed';
export type PlotStatus = 'in_progress' | 'failed' | 'completed';
export type GenericCellData = Partial<Record<string, string | number | boolean | null>>;
export type PlotMapping = {
    x_axis: string;
    y_axis: string;
    [key: string]: string;
};

export type PlotImageSizeFormat = 'square' | 'wide' | 'tall' | 'auto';
export type PlotImageType = 'pdf' | 'png';

export type PlotImages = Record<PlotImageType, Record<PlotImageSizeFormat, string>>;

export interface TabularData<DATA extends GenericCellData = GenericCellData> {
    headers: string[];
    count?: number;
    items: DATA[];
}

export interface ExperimentData<DATA extends GenericCellData = GenericCellData> extends TabularData<DATA> {
    status: ExperimentDataStatus;
    data_hash: string;
    pipeline_status?: PipelineStatus | null;
    plot_mapping?: PlotMapping;
}

export const isArrowPlotData = <ITEM extends GenericCellData>(
    data?: AnyExperimentData | ExperimentData | ArrowPlotData<ITEM> | TabularData | null,
): data is ArrowPlotData<ITEM> => {
    if (!data) {
        return false;
    }
    return !Array.isArray(data.items);
};

export interface PipelineStatusPlotData<DATA extends GenericCellData = GenericCellData> extends ExperimentData<DATA> {
    pipeline_status: PipelineStatus;
    plot_status?: PlotStatus;
}

export interface PlotPipelinePlotData<DATA extends GenericCellData = GenericCellData>
    extends PipelineStatusPlotData<DATA> {
    plot_status: PlotStatus;
    plots?: {
        images: PlotImages;
    };
}

export interface ArrowPlotData<ITEM extends GenericCellData> {
    count: number;
    headers: string[];
    pipeline_status?: PipelineStatus;
    plot_status?: PlotStatus;
    status?: ExperimentDataStatus;
    items: ArrowData<ITEM> | ITEM[];
    data_hash: string;
    plot_mapping?: PlotMapping;
}

export type DEGLog2CPMZScores = {
    Average_ZscoreLog2CPM_Control: number;
    Average_ZscoreLog2CPM_Experimental: number;
    Median_ZscoreLog2CPM_Control: number;
    Median_ZscoreLog2CPM_Experimental: number;
};

export type DEGZScores = {
    Average_Zscore_Control: number;
    Average_Zscore_Experimental: number;
    Median_Zscore_Control: number;
    Median_Zscore_Experimental: number;
};

export type DEGSample = {
    Log2_Fold_Change: number;
    P_Value: number;
    Adj_P_Value: number;
    Gene_Symbol: string;
    peak_id?: string;
    gene_id?: string;
    Group: string;
    probe_id?: string;
    protein_id?: string;
    metabolite_id?: string;
    Average_Expression: number;
} & (DEGLog2CPMZScores & DEGZScores);

export type SeuratGeneSetSample = {
    Adj_P_Value: string;
    Bg_Ratio: string;
    Count: string;
    Gene_Ratio: string;
    Gene_Set: string;
    Genes: string;
    Group: string;
    Neg_log10_Adj_P_Value: string;
    P_Value: string;
    Q_Value: string;
    Source: string;
};

export type PCASample = GenericCellData & { Sample_ID: string; group_id: string; group_name: string };

export type EnrichmentColorValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type EnrichmentItem = {
    /** The X-Axis value on the enrichment plot */
    Rank: number;

    /** The Y-Axis value on the enrichment plot */
    Running_ES: number | null;

    /** If the vertical "gene tick" bar should be shown for this data point or not */
    Gene_Tick: boolean;

    /** The {EnrichmentColorValue} for this data point to show on the heatmap */
    Color_Value: EnrichmentColorValue;

    /** The shortname of the gene set this datapoint represents */
    Gene_Set_Shortname: string;

    /**
     * The name of the gene
     */
    Gene?: string | null;
};

export type IGVTrackItemType = 'sample' | 'called_peaks';
export type IGVTrackItem = {
    group_id: number;
    group_name: string;
    name: string;
    url: string;
    type: IGVTrackItemType;
};

export type SurvivalSample = {
    sample_group_name: string;
    sample_group_id: number;
    x_value: number;
    y_value: number;
    tick: 0 | 1;
    event: 0 | 1;
};

export interface NetworkGraphNode extends d3.SimulationNodeDatum {
    group: number;
    id: string;
    index?: number;
}
export interface NetworkGraphLink {
    edge_type: string;
    i: number;
    multiIdx?: number;
    targetDistance?: number;
    value: string | number;
    source: { x: number; y: number; id?: string; group?: number } | string;
    target: { x: number; y: number; id?: string; group?: number } | string;
    index?: number;
    group?: number;
    id: number;
}
export type NetworkGraphData = {
    nodes: NetworkGraphNode[];
    links: NetworkGraphLink[];
};
export type NetworkGraphItem = {
    protein_a: string;
    protein_b: string;
    edge_value: string;
    edge_type: string;
};
export type VennDiagramItem = {
    count: string;
    hiddenLabel: string;
    label: string;
    list_names: string;
    set?: string;
    sets?: string;
    value: string;
};

export type RidgePlotItem = {
    group_id: string;
    group_name: string;
    value: string;
};

export type PreviewGraph = GenericCellData & { url: string };
export type ExternalPlot = GenericCellData & { url: string };

export type DEGData = PipelineStatusPlotData<DEGSample>;
export type DEGDataV2 = ArrowPlotData<DEGSample>;
export type SeuratGeneSetData = ArrowPlotData<SeuratGeneSetSample>;
export type PCAData = PipelineStatusPlotData<PCASample>;
export type SummaryData = ExperimentData<SummaryAnalysisSample>;
export type EnrichmentData = PipelineStatusPlotData<EnrichmentItem>;
export type GeneSetData = PipelineStatusPlotData<GeneSet>;
export type IGVData = PipelineStatusPlotData<IGVTrackItem>;
export type SurvivalData = PipelineStatusPlotData<SurvivalSample>;
export type ImageData = Record<string, unknown>;
export type ScorePlotData = PipelineStatusPlotData<ScorePlotDatum>;
export type PreviewGraphData = PreviewGraph;
export type ExternalPlotData = ExternalPlot;

// Category-specific data
export type ComparativeAnalysisPlotData = DEGData;

export type SummaryAnalysisPlotData = SummaryData;
export type DimensionalityReductionAnalysisPlotData = PCAData;
export type PathwayAnalysisPlotData = EnrichmentData | GeneSetData;
export type OtherAnalysisPlotData = IGVData | SurvivalData | ImageData;
export type AnyExperimentData = { plot_status?: PlotStatus } & (
    | DEGData
    | DEGDataV2
    | PCAData
    | SummaryData
    | EnrichmentData
    | IGVData
    | SurvivalData
    | GeneSetData
    | PlotPipelinePlotData
    | SeuratGeneSetData
);
