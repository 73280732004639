import DataTable from '@components/dataTable/DataTable';
import Endpoints from '@services/Endpoints';
import LoadingMessage from '@components/LoadingMessage';
import TextInput from '@components/forms/TextInput';
import { FormControl, IconButton, MenuItem, Select } from '@mui/material';
import { ArrowsExpandIcon, SearchIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { formatTableHeader, isNotBlank } from '@util/StringUtil';
import { Alert } from '@mui/material';
import DownloadDataButton from '@components/DownloadDataButton';
import Plot from '@models/Plot';
import { getPlotDisplayTitle, getPlotTitleFromListItem } from '@components/plots/PlotUtil';
import Experiment from '@models/Experiment';
import { generatePlotDataFileName } from '@util/ExperimentUtil';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { isDefined } from '@util/TypeGuards';
import { PipelineStatusAnalysis } from '@models/analysis/ExperimentAnalysis';
import usePlot from '@hooks/usePlot';
import { analysisHasDEGTable, analysisHasResults } from '@models/ExperimentType';
import { DisplayTypeIcon } from '@components/experiments/ExperimentIcons';
import Button from '@components/Button';
import SearchableGeneSetTableDialog from '@components/experiments/SearchableGeneSetTableDialog';
import { isArrowPlotData, PipelineStatus } from '@models/ExperimentData';
import useExperimentSettings from '@hooks/useExperimentSettings';
import { analysisHeaderRenderer, geneLinkCellRenderer } from '@components/dataTable/cellRenderers';
import PeakAnalysisFiles from '@components/experiments/PeakAnalysisFiles';
import { useExperimentDetailViewContext } from '@/src/contexts/ExperimentDetailViewContext';
import Logger from '@util/Logger';
import useApi from '@/src/hooks/useApi';

const logger = Logger.make('ResultsDataTableView');

const MissingControlErrorMessage: FunctionComponent<{
    activePlot?: Plot | null;
    plotDataError?: { code?: string } | null;
}> = ({ activePlot, plotDataError }) => {
    if (plotDataError?.code !== 'missing_control_experiment_group') {
        return null;
    }

    if (!!activePlot) {
        return (
            <div className="text-center">
                Results for <span className="font-semibold">{getPlotDisplayTitle(activePlot)}</span> will be available
                once your analysis has been set up
            </div>
        );
    }

    return <div className="text-center">Results will be available here once a quantitative analysis has been run</div>;
};

const PipelineStatusMessage: FunctionComponent<{ pipelineStatus?: PipelineStatus; activePlot?: Plot | null }> = ({
    pipelineStatus,
    activePlot,
}) => {
    if (pipelineStatus === 'in_progress') {
        return (
            <div className="py-4">
                <LoadingMessage noPadding>
                    <div>
                        {activePlot && (
                            <div className="text-center">
                                Results for <span className="font-semibold">{getPlotDisplayTitle(activePlot)}</span> is
                                currently processing
                            </div>
                        )}
                        {!activePlot && <div className="text-center">Results data is currently processing</div>}
                    </div>
                </LoadingMessage>
            </div>
        );
    }

    if (pipelineStatus === 'failed') {
        return (
            <Alert severity="error" className=" mx-auto max-w-lg">
                <div className="">Failed to process results</div>
            </Alert>
        );
    }
    return null;
};

const ResultsNotAvailableMessage = () => {
    return <div className="text-center">Results will be available here once a quantitative analysis has been run</div>;
};

const DEFAULT_PAGE_SIZE = 500;
export type Props = {
    experiment: Experiment;
    selectedPlot?: Plot | null;
    viewDataOnly?: boolean;
};
const ResultsDataTableView = ({ experiment, selectedPlot: plotBeingEdited, viewDataOnly }: Props) => {
    const experimentId = experiment.uuid;
    const [activePlot, setActivePlot] = useState<Plot | null>(null);
    const [searchValue, setSearchValue] = useState(''); // holds the form value
    const [searchTerm, setSearchTerm] = useState(''); // used for the term sent to the API
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedPlotId, setSelectedPlotId] = useState<string | null>(null);
    const [geneSetOpen, setGeneSetOpen] = useState(false);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [sortDesc, setSortDesc] = useState(false);
    const { plotListItems } = useExperimentDetailViewContext();
    const api = useApi();

    useEffect(() => {
        if (!selectedPlotId) return;

        const fetchPlot = async () => {
            try {
                const newPlot = await api.get<Plot>(
                    Endpoints.lab.experiment.plot.base({ plotId: selectedPlotId, experimentId }),
                );
                setActivePlot(newPlot);
            } catch {
                logger.warn('Failed to fetch plot');
            }
        };

        fetchPlot();
    }, [selectedPlotId]);

    const { activePlotId } = useMemo(() => {
        const filteredPlots = plotListItems?.filter((p) => analysisHasResults(p.analysis_type)) ?? [];
        const chosenPlot = filteredPlots?.find((p) => p.uuid === selectedPlotId);
        const plotId = selectedPlotId ? selectedPlotId : chosenPlot ? chosenPlot.uuid : filteredPlots[0]?.uuid ?? null;
        return { activePlotId: plotId, availablePlots: filteredPlots };
    }, [plotBeingEdited, plotListItems, selectedPlotId]);

    const { overrides, plotData, plotDataError, plotDataLoading, plotLoading } = usePlot({
        experiment,
        plotId: activePlotId,
        revalidateOnMount: true,
        searchTerm: searchTerm,
        sortDataBy: isNotBlank(sortBy) ? `${sortDesc ? '-' : ''}${sortBy}` : undefined,
        dataLimit: DEFAULT_PAGE_SIZE,
    });
    const legacyAnalysisSummaryResults = () => {
        if (
            activePlot?.display?.display_type === 'dot_plot' &&
            activePlot?.analysis_type === 'seurat_over_representation'
        )
            return true;
        return false;
    };

    const hasItems = isArrowPlotData(plotData) ? (plotData.count ?? 0) > 0 : (plotData?.items ?? []).length > 0;
    const hasPlots = (plotListItems?.length ?? 0) > 0;
    const plotTitle = getPlotDisplayTitle(activePlot);
    const pipelineStatus = (activePlot?.analysis as PipelineStatusAnalysis)?.pipeline_status ?? 'completed';
    const searchColumnName = formatTableHeader(plotData?.headers?.[0] ?? 'Gene');
    const downloadFilename = activePlot ? generatePlotDataFileName({ experiment, plot: activePlot }) : '';
    const isEditOpen = isDefined(plotBeingEdited);
    const loading = !!plotLoading || !!plotDataLoading;
    const pipelineLoading = pipelineStatus === 'in_progress';
    const downloadEnabled =
        !pipelineLoading && (hasItems || searchOpen) && activePlot?.analysis?.analysis_type !== 'peak';
    const searchEnabled = analysisHasDEGTable(activePlot?.analysis_type) && !pipelineLoading;
    const summaryDownloadEnabled = activePlot?.analysis?.has_results_summary || legacyAnalysisSummaryResults();
    const { getAnalysisInfo } = useExperimentSettings(experiment);

    useEffect(() => {
        if (plotBeingEdited && isNotBlank(plotBeingEdited?.analysis?.uuid)) {
            setSelectedPlotId(plotBeingEdited.uuid);
        } else if (!selectedPlotId && plotListItems?.length) {
            const defaultPlotId = plotListItems[0].uuid;
            setSelectedPlotId(defaultPlotId);
        }
    }, [plotBeingEdited, plotListItems, selectedPlotId]);

    useEffect(() => {
        if (activePlot?.analysis_type) {
            const analysisInfo = getAnalysisInfo(activePlot.analysis_type);
            if (analysisInfo?.category.shortname === 'comparative') {
                setSortBy((currentSort) => {
                    if (isDefined(currentSort)) {
                        return currentSort;
                    }
                    return 'Adj_P_Value';
                });
            }
        }
    }, [activePlot]);

    useEffect(() => {
        if (!searchEnabled) {
            setSearchOpen(false);
        }
    }, [searchEnabled]);

    const conditionalHeaderRenderer = useCallback(
        (header: string, index: number) => {
            switch (activePlot?.analysis_type) {
                case 'functional_annotation':
                    return analysisHeaderRenderer(header, index, activePlot?.analysis_type ?? '');
                default:
                    return undefined;
            }
        },
        [activePlot?.analysis_type],
    );

    const renderData = useCallback(() => {
        return (
            <>
                {activePlot?.analysis?.analysis_type === 'peak' ? (
                    <div>
                        <PeakAnalysisFiles plot={activePlot} experiment={experiment} />
                    </div>
                ) : (
                    <>
                        {!!activePlot && !activePlot?.analysis?.uuid && <ResultsNotAvailableMessage />}
                        {loading && activePlotId && (
                            <LoadingMessage
                                message={searchValue ? `Searching for "${searchTerm.trim()}"` : 'Loading results data'}
                                size={24}
                            />
                        )}
                        {!searchOpen && (!plotData || !hasItems) && !loading && !pipelineLoading && (
                            <div className="text-center">
                                No results found for{' '}
                                {plotTitle ? <span className="font-semibold">{plotTitle}</span> : 'this plot'}
                                {activePlot?.analysis_type === 'external' ? externalPlotMessage() : null}
                            </div>
                        )}
                        {(!plotData || !hasItems) && !loading && searchTerm && !pipelineLoading && (
                            <div className="pt-8 text-center">
                                No genes found matching{' '}
                                <span className="font-semibold">&quot;{searchTerm.trim()}&quot;</span>
                            </div>
                        )}
                        {plotData && hasItems && !pipelineLoading && (
                            <DataTable
                                data={plotData}
                                tableColor="bg-cyan-100 text-cyan-900"
                                key={plotData?.data_hash ?? selectedPlotId ?? 'no_plot'}
                                cellRenderer={geneLinkCellRenderer({ experiment })}
                                onSortedChange={(header, desc) => {
                                    setSortBy(header);
                                    setSortDesc(desc ?? false);
                                }}
                                sortBy={sortBy ?? undefined}
                                sortable
                                sortDesc={sortDesc}
                                manualSortBy
                                pageSize={isArrowPlotData(plotData) ? DEFAULT_PAGE_SIZE : undefined}
                                showSummaryStats={activePlot?.analysis?.analysis_type === 'differential_expression'}
                                viewDataOnly={viewDataOnly}
                                headerRenderer={conditionalHeaderRenderer}
                            />
                        )}
                    </>
                )}
            </>
        );
    }, [
        plotData,
        hasItems,
        activePlot,
        loading,
        pipelineLoading,
        searchOpen,
        searchTerm,
        sortBy,
        sortDesc,
        viewDataOnly,
    ]);

    if (!hasPlots && !loading) {
        return <ResultsNotAvailableMessage />;
    }

    const closeSearch = () => {
        setSearchOpen(false);
        setSearchTerm('');
        setSearchValue('');
    };

    const toggleSearch = () => {
        if (!searchOpen) {
            setSearchOpen(true);
        } else {
            closeSearch();
        }
    };

    const handleAnalysisChanged = (id: string) => setSelectedPlotId(id);

    const externalPlotMessage = () => (
        <p>
            Follow{' '}
            <a href="http://help.pluto.bio" target="_blank" rel="noreferrer">
                this link
            </a>{' '}
            to find more information on how to link data to this chart.
        </p>
    );

    if (viewDataOnly) {
        return renderData();
    }

    return (
        <div>
            <form
                className="mb-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    setSearchTerm(searchValue);
                }}
            >
                {hasPlots && (
                    <label
                        className="mb-2 block font-semibold text-default md:mb-0"
                        htmlFor={`exp_${experiment.uuid}_plot_picker`}
                    >
                        {'Displaying results for plot'}
                    </label>
                )}
                <div className="mb-4 flex flex-col space-y-8 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0">
                    <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0">
                        {hasPlots && !!plotListItems && plotListItems.length > 1 && !isEditOpen ? (
                            <FormControl
                                variant="outlined"
                                disabled={isEditOpen}
                                className="flex flex-col justify-start md:flex-row md:items-center md:space-x-2"
                                id={`exp_${experiment.uuid}_plot_picker`}
                            >
                                <Select
                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                    margin="dense"
                                    value={activePlotId}
                                    placeholder="Select a comparison"
                                    className="max-w-md"
                                    onChange={(e) => handleAnalysisChanged(e.target.value as string)}
                                >
                                    {plotListItems.map((p) => (
                                        <MenuItem value={p.uuid} key={p.uuid} className="max-w-md">
                                            <div className="flex items-center space-x-2">
                                                <span className="shrink-0 ">
                                                    <DisplayTypeIcon
                                                        type={p.display_type}
                                                        width={20}
                                                        height={18}
                                                        className={isEditOpen ? 'text-gray-400' : undefined}
                                                    />
                                                </span>
                                                <span className="whitespace-normal">
                                                    {getPlotTitleFromListItem(p) || 'No title'}
                                                </span>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <>
                                {activePlot ? (
                                    <div className="flex items-center space-x-2">
                                        <span className="shrink-0 ">
                                            <DisplayTypeIcon
                                                type={activePlot.display.display_type}
                                                width={20}
                                                height={18}
                                            />
                                        </span>
                                        <span className="whitespace-normal">{getPlotDisplayTitle(activePlot)}</span>
                                    </div>
                                ) : (
                                    <>{!!loading ? <p>Loading plot data...</p> : <p>No plot selected</p>}</>
                                )}
                            </>
                        )}
                        {activePlot?.analysis_type === 'gene_set_enrichment' && (
                            <div>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => setGeneSetOpen(true)}
                                    startIcon={<ArrowsExpandIcon width={18} />}
                                >
                                    <span className="whitespace-nowrap">View all gene sets</span>
                                </Button>
                                <SearchableGeneSetTableDialog
                                    open={geneSetOpen}
                                    setOpen={setGeneSetOpen}
                                    experiment={experiment}
                                    plot={activePlot}
                                    hideSelectColumn
                                />
                            </div>
                        )}
                    </div>
                    <div className="mb-4 flex items-start space-x-4 md:justify-end md:pt-1">
                        {searchEnabled && (
                            <div className="rounded-full border border-indigo-100 bg-white">
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    onClick={toggleSearch}
                                    className="text-indigo-500"
                                    size="large"
                                >
                                    {searchOpen ? (
                                        <XIcon className="h-4 w-4 text-indigo-500" />
                                    ) : (
                                        <SearchIcon className="h-4 w-4 text-indigo-500" />
                                    )}
                                </IconButton>
                            </div>
                        )}
                        {downloadEnabled && activePlotId && (
                            <div className="rounded-full bg-white">
                                <DownloadDataButton
                                    baseFilename={downloadFilename}
                                    endpoint={(filename: string) =>
                                        Endpoints.lab.experiment.downloadPlotData(
                                            {
                                                experimentId,
                                                plotId: activePlotId,
                                            },
                                            { analysis_id: overrides?.analysis_id ?? undefined, filename },
                                        )
                                    }
                                    summaryEndpoint={
                                        summaryDownloadEnabled
                                            ? (filename: string) =>
                                                  Endpoints.lab.experiment.analysis.summaryDownload(
                                                      {
                                                          experimentId,
                                                          analysisId: activePlot?.analysis?.uuid ?? '', // quick way to ensure the correct type here
                                                      },
                                                      { filename },
                                                  )
                                            : undefined
                                    }
                                    color="secondary"
                                />
                            </div>
                        )}
                    </div>
                </div>

                {searchEnabled && searchOpen && (
                    <TextInput
                        value={searchValue ?? ''}
                        name="filter"
                        className="mb-4 w-full"
                        disableFormik
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        placeholder={`Search in ${searchColumnName}...`}
                        iconRight={
                            <div className="flex items-center">
                                {searchValue && (
                                    <IconButton
                                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                        onClick={() => {
                                            setSearchValue('');
                                            setSearchTerm('');
                                        }}
                                        size="large"
                                    >
                                        <XCircleIcon className="h-4 w-4 text-indigo-500" />
                                    </IconButton>
                                )}
                                {
                                    <IconButton
                                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                        type="submit"
                                        size="large"
                                    >
                                        <SearchIcon className="h-4 w-4 text-indigo-500" />
                                    </IconButton>
                                }
                            </div>
                        }
                    />
                )}
            </form>

            <MissingControlErrorMessage activePlot={activePlot} plotDataError={plotDataError} />
            <PipelineStatusMessage pipelineStatus={pipelineStatus} activePlot={activePlot} />

            {renderData()}
        </div>
    );
};

export default ResultsDataTableView;
