import Experiment from '@models/Experiment';
import { Renderer } from 'react-table';
import { GenericCellData } from '@models/ExperimentData';
import DefaultTableCell from '@components/dataTable/DefaultTableCell';
import { OrganismID } from '@models/Organism';
import HumanGeneLinkCell from '@components/dataTable/HumanGeneLinkCell';
import MouseGeneLinkCell from '@components/dataTable/MouseGeneLinkCell';
import RatGeneLinkCell from '@components/dataTable/RatGeneLinkCell';
import FruitflyGeneLinkCell from '@components/dataTable/FruitflyGeneLinkCell';
import React from 'react';
import { TableHeaderIcon } from '@components/dataTable/TableHeaderIcon';
import SampleDataFastqFileCell from '@components/dataTable/SampleDataFastqFileCell';
import ZebrafishGeneLinkCell from '@components/dataTable/ZebrafishGeneLinkCell';
import CellLineCell from '@components/dataTable/cellLine';
import { functionalAnnotationAnalysisHeaderMapping } from '@/src/models/analysis/PlotDataHeaderAnalysis';
import { capitalize } from '@mui/material';

export const geneLinkCellRenderer = ({ experiment }: { experiment: Experiment }) => {
    const organism_id = experiment.organism?.shortname;
    return (header: string): Renderer<GenericCellData> => {
        const isGeneHeader = ['gene_id', 'gene_symbol', 'gene'].includes(header?.toLowerCase().trim());

        if (!isGeneHeader) {
            return DefaultTableCell;
        }
        switch (organism_id) {
            case OrganismID.human:
                return HumanGeneLinkCell;
            case OrganismID.mouse:
                return MouseGeneLinkCell;
            case OrganismID.rat:
                return RatGeneLinkCell;
            case OrganismID.fruitfly:
                return FruitflyGeneLinkCell;
            case OrganismID.zebrafish:
                return ZebrafishGeneLinkCell;
            default:
                return DefaultTableCell;
        }
    };
};
export const analysisHeaderRenderer = (header: string, _: number, analysisType: string) => {
    if (analysisType === 'functional_annotation') {
        return functionalAnnotationAnalysisHeaderMapping[header] || capitalize(header);
    }
    return header;
};

export const assayHeaderRenderer = (header: string, index: number): Renderer<GenericCellData> => {
    const shouldFormatHeader = index === 0 || ['gene_symbol', 'gene_id'].includes(header.trim().toLowerCase());
    return shouldFormatHeader ? <TableHeaderIcon text={header} /> : header;
};

export const sampleDataFastqFileRenderer = () => {
    return (header: string): Renderer<unknown> | undefined => {
        if (!['r1_fastq', 'r2_fastq'].includes(header?.toLowerCase().trim())) {
            return DefaultTableCell;
        }

        return SampleDataFastqFileCell;
    };
};

const cellLineRenderer = () => () => CellLineCell;

export const combinedCellRenderer = () => {
    return (header: string): Renderer<unknown> | undefined => {
        const formattedHeader = header.toLowerCase().replace(' ', '_');
        const possibleHeaders = ['cell_line', 'cellline', 'celllines'];

        if (['r1_fastq', 'r2_fastq'].includes(formattedHeader)) {
            return sampleDataFastqFileRenderer()(header);
        } else if (possibleHeaders.includes(formattedHeader)) {
            return cellLineRenderer()();
        } else {
            return DefaultTableCell;
        }
    };
};
